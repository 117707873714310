<template>
  <Card>
    <template #title>Precios </template>
    <template #content>
      <div id="content">
        <ConfirmDialog />
        <div id="form">
          <Fieldset legend="Origen" :toggleable="true">
            <div class="item">
              <div class="field">Email</div>
              <label class="value">{{
                props.originAddress.contact_email
              }}</label>
            </div>
            <div class="item">
              <div class="field">Dirección</div>
              <label class="value">{{ props.originAddress.address }}</label>
            </div>
            <div class="item">
              <div class="field">Teléfono</div>
              <label class="value">{{
                props.originAddress.contact_phone
              }}</label>
            </div>
          </Fieldset>
          <br />
          <Fieldset legend="Destino" :toggleable="true">
            <div class="item">
              <div class="field">Email</div>
              <label class="value">{{ props.shipment.buyer_email }}</label>
            </div>
            <div class="item">
              <div class="field">Dirección</div>
              <label class="value">{{
                props.shipment.shipping_address_1 ||
                " " ||
                props.shipment.shipping_address_2
              }}</label>
            </div>
            <div class="item">
              <div class="field">Teléfono</div>
              <label class="value">{{ props.shipment.shipping_phone }}</label>
            </div>
          </Fieldset>
          <br />
          <Fieldset legend="Dimensiones" :toggleable="true">
            <div class="item">
              <div class="field">Medidas</div>
              <label class="value"
                >{{ props.dimensions.height }} x {{ props.dimensions.width }} x
                {{ props.dimensions.length }}</label
              >
            </div>
            <div class="item">
              <div class="field">Peso Volumétrico</div>
              <label class="value">{{
                props.dimensions.volumetric_weight
              }}</label>
            </div>
            <div class="item">
              <div class="field">Peso Físico</div>
              <label class="value">{{
                props.dimensions.physical_weight
              }}</label>
            </div>
            <div class="item">
              <div class="field"># Productos</div>
              <label class="value">{{ props.dimensions.quantity }}</label>
            </div>
          </Fieldset>
          <br />
          <Fieldset legend="Courier" :toggleable="true">
            <div class="item">
              <div class="field">Courier</div>
              <label class="value">
                <img
                  :src="`/layout/images/couriers/${props.price.icon}`"
                  class="p-mr-2"
                  width="70"
                />
              </label>
            </div>
            <div class="item">
              <div class="field">Por Pagar</div>
              <label class="value">No</label>
            </div>
            <div class="item">
              <div class="field">Valor Estimado</div>
              <label class="value">{{ props.price.price }}</label>
            </div>
          </Fieldset>
          <br />
          <Button
            icon="pi pi-arrow-circle-left"
            severity="secondary"
            rounded
            outlined
            aria-label="Filter"
            style="float: left"
            @click="props.navigate('4')"
          />
          <ProgressSpinner
            v-if="loading"
            style="
              width: 50px;
              height: 50px;
              margin-top: 20px;
              margin-left: 40%;
            "
            class="spinner"
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
          <Button
            v-else-if="!finished"
            label="Crear Envío"
            style="float: right"
            @click="createShipment()"
          />
        </div>
      </div>
    </template>
  </Card>
</template>
<script setup>
import ConfirmDialog from "primevue/confirmdialog";
import Fieldset from "primevue/fieldset";
import { defineProps } from "vue";
import { newShipment, getAttributes } from "@/api/courier";
import { printLabels } from "@/services/shipments";
import { useConfirm } from "primevue/useconfirm";
import ProgressSpinner from "primevue/progressspinner";
import { ref } from "vue";
const props = defineProps({
  shipment: Object,
  dimensions: Object,
  originAddress: Object,
  price: Object,
  showToast: Function,
  navigate: Function,
});
const finished = ref(false);
const confirm = useConfirm();
const loadCourierAttributes = async () => {
  const response = await getAttributes(props.price.courierId);
  return response.data;
};
const loading = ref(false);
const findAttribute = (attributes, name) => {
  const attribute = attributes.find(
    (attribute) => attribute.attribute === name
  );
  return attribute ? attribute.value : "";
};

const printConfirmation = (labelData) => {
  confirm.require({
    message: "Orden de envío generada, ¿deseas imprimir la etiqueta ahora?",
    header: "Desea imprimir la etiqueta?",
    icon: "pi pi-info-circle",
    acceptLabel: "Si",
    rejectLabel: "No",
    accept: () => {
      printLabels([labelData]);
    },
  });
};

const createShipment = async () => {
  const courierAttributes = await loadCourierAttributes(props.price.courierId);
  const cardNumber = findAttribute(courierAttributes, "card_number");
  const marketplaceRut = findAttribute(courierAttributes, "marketplace_rut");
  const payload = {
    header: {
      customerCardNumber: cardNumber,
      countyOfOriginCoverageCode: props.price.originCountyCode,
      labelType: 2,
      marketplaceRut: marketplaceRut,
    },
    details: [
      {
        addresses: [
          {
            countyCoverageCode: props.price.originCountyCode,
            streetName:
              props.shipment.shipping_address_1 ||
              " " ||
              props.shipment.shipping_address_2,
            addressType: "DEST",
          },
          {
            countyCoverageCode: props.price.originCountyCode,
            streetName: props.originAddress.address,
            addressType: "DEV",
          },
        ],
        contacts: [
          {
            name: props.originAddress.contact_name,
            phoneNumber: props.originAddress.contact_phone,
            mail: props.originAddress.contact_email,
            contactType: "R",
          },
          {
            name: props.shipment.buyer_name,
            phoneNumber: props.shipment.shipping_phone,
            mail: props.shipment.buyer_email,
            contactType: "D",
          },
        ],
        packages: [
          {
            weight: props.dimensions.physical_weight,
            height: props.dimensions.height,
            width: props.dimensions.width,
            length: props.dimensions.length,
            serviceDeliveryCode: "3",
            productCode: "3",
            deliveryReference: props.shipment.id,
            groupReference: "GRUPO",
            declaredValue: props.shipment.total,
            declaredContent: 5,
            receivableAmountInDelivery: props.shipment.total,
          },
        ],
      },
    ],
    shipmentId: props.shipment.id,
  };
  try {
    loading.value = true;
    const response = await newShipment(props.price.courierId, payload);
    printConfirmation(response.data.detail[0].label.labelData);
    loading.value = false;
    finished.value = true;
  } catch (err) {
    props.showToast(
      "error",
      "Error",
      "Ha ocurrido un error al generar la orden de envío"
    );
    loading.value = false;
  }
};
</script>

<style scoped>
#content {
  display: flex;
  justify-content: center;
}
#form {
  width: 750px;
}
.item {
  height: 35px;
}
.field {
  float: left;
}
.value {
  float: right;
}
</style>
