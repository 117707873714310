<template>
  <Card>
    <template #title>Destino </template>
    <template #content>
      <div id="content">
        <div id="form">
          <div class="field">
            <span class="p-float-label">
              <InputText
                id="company-rut"
                class="p-inputtext"
                v-model="destinationAddress.contact_name"
                style="width: 95%"
              />
              <label for="company-rut">Nombre</label>
            </span>
          </div>
          <div class="formgroup-inline field">
            <span class="p-float-label">
              <InputText
                id="company-rut"
                class="p-inputtext"
                v-model="destinationAddress.contact_phone"
              />
              <label for="company-rut">Teléfono</label>
            </span>
            <span class="p-float-label right">
              <InputText
                class="p-inputtext"
                v-model="destinationAddress.email"
              />
              <label for="company-rut">Mail</label>
            </span>
          </div>
          <div class="flex flex-wrap gap-3 radio">
            Tipo de entrega:
            <div
              class="flex align-items-center"
              style="padding-right: 15px; padding-left: 50px"
            >
              <RadioButton
                inputId="example1"
                name="example"
                value="domicilio"
                v-model="deliveryType"
              />
              <label class="ml-2">Domicilio</label>
            </div>
            <div class="flex align-items-center">
              <RadioButton
                inputId="example2"
                name="example"
                value="sucursal"
                v-model="deliveryType"
              />
              <label class="ml-2">Sucursal</label>
            </div>
          </div>
          <div class="field">
            <span class="p-float-label">
              <InputText
                id="company-web"
                class="p-inputtext"
                v-model="destinationAddress.address"
                style="width: 95%"
              />
              <label for="company-web">Dirección</label>
            </span>
          </div>
          <div class="formgroup-inline field">
            <span class="p-float-label">
              <InputText
                id="company-web"
                class="p-inputtext"
                v-model="destinationAddress.complement"
              />
              <label for="company-web">Complemento</label>
            </span>
            <span class="p-float-label right">
              <InputText
                id="company-web"
                class="p-inputtext"
                v-model="destinationAddress.city"
              />
              <label for="company-web">Comuna</label>
            </span>
          </div>
          <div class="flex align-items-center" style="padding-bottom: 10px">
            <Checkbox inputId="example1" name="example" value="Cheese" />
            <label for="example1" class="ml-2">
              Guardar Dirección de Destino
            </label>
          </div>
          <Button
            icon="pi pi-arrow-circle-left"
            severity="secondary"
            rounded
            outlined
            aria-label="Filter"
            style="float: left"
            @click="props.navigate('1')"
          />
          <Button
            icon="pi pi-arrow-circle-right"
            rounded
            outlined
            aria-label="Filter"
            style="float: right"
            @click="props.navigate('3')"
            :disabled="destinationAddress.address.length === 0"
          />
        </div>
      </div>
    </template>
  </Card>
</template>
<script setup>
import { ref, defineProps } from "vue";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";

const destinationAddress = ref({
  region_iso_code: props.shipment.shipping_state,
  contact_name: props.shipment.shipping_name,
  contact_phone: props.shipment.shipping_phone,
  city: props.shipment.shipping_city,
  address: props.shipment.shipping_address_1,
  complement: props.shipment.shipping_address_2,
  email: props.shipment.buyer_email,
});

const props = defineProps({
  navigate: Function,
  shipment: Object,
  addresses: Array,
});

const deliveryType = ref("domicilio");
</script>

<style scoped>
#content {
  display: flex;
  justify-content: center;
}
#form {
  padding: 20px;
  width: 385px;
  border-style: solid;
  border-color: #d1d1d1;
  border-radius: 5px;
}
.radio {
  padding-bottom: 10px;
}
.field {
  margin-top: 30px;
}
.right {
  margin-left: 10px;
}
</style>
