<template>
  <Card>
    <template #title>Precios </template>
    <template #content>
      <div id="content">
        <div id="form">
          <span class="p-float-label field">
            <Dropdown
              v-model="selectedCourier"
              :options="couriers"
              optionLabel="name"
              placeholder="Courier..."
              class="w-full md:w-11rem"
            />
          </span>
          <CourierPricing
            v-if="loaded"
            :originCoverage="originCoverage"
            :destinationCoverage="destinationCoverage"
            :sizes="sizes"
            :showToast="props.showToast"
            :setPrice="props.setPrice"
            :isShipping="true"
          />
          <div id="buttons">
            <Button
              icon="pi pi-arrow-circle-left"
              severity="secondary"
              rounded
              outlined
              aria-label="Filter"
              style="float: left"
              @click="props.navigate('3')"
            />
            <Button
              icon="pi pi-arrow-circle-right"
              rounded
              outlined
              aria-label="Filter"
              style="float: right"
              @click="props.navigate('5')"
              :disabled="props.price.price === undefined"
            />
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>
<script setup>
import { ref, defineProps, watch } from "vue";
import Dropdown from "primevue/dropdown";
import { getCouriers, getAreas } from "@/api/courier";
import { isSimilarString } from "@/services/utils";
import CourierPricing from "./../../common/CourierPricing.vue";

const props = defineProps({
  navigate: Function,
  setPrice: Function,
  customer: Object,
  shipment: Object,
  dimensions: Object,
  originAddress: Object,
  price: Object,
  showToast: Function,
});
const couriers = ref([]);
const selectedCourier = ref(null);
const loaded = ref(false);
const loadCouriers = async () => {
  const response = await getCouriers(props.customer.id);
  couriers.value = response.data;
  selectedCourier.value = response.data[0];
};
const originCoverage = ref({});
const destinationCoverage = ref({});
const sizes = ref(props.dimensions);

watch(selectedCourier, async () => {
  loaded.value = false;
  prepareQuoterData();
});

loadCouriers();

const loadAreas = async (region_code) => {
  const response = await getAreas(selectedCourier.value.id, region_code);
  return response.data.coverageAreas;
};

const prepareQuoterData = async () => {
  let coverageAreas = await loadAreas(props.shipment.region_code);
  const destinationAreaCode = findCoverageArea(
    coverageAreas,
    props.shipment.shipping_city
  );
  if (!destinationAreaCode) {
    alert("No se encontró cobertura para la comuna de destino");
    // TODO: Open modal to choose the coverage area
    return;
  }
  coverageAreas = await loadAreas(props.originAddress.region_code);
  const originCoverageArea = findCoverageArea(
    coverageAreas,
    props.originAddress.city
  );
  if (!originCoverageArea) {
    alert("No se encontró cobertura para la comuna de origen");
    // TODO: Open modal to choose the coverage area
    return;
  }

  originCoverage.value = originCoverageArea;
  destinationCoverage.value = destinationAreaCode;
  loaded.value = true;
};

const findCoverageArea = (data, city) => {
  const area = data.find((element) =>
    isSimilarString(element.countyName.toLowerCase(), city.toLowerCase())
  );
  return area;
};
</script>

<style scoped>
#content {
  display: flex;
  justify-content: center;
}
#form {
  padding: 20px;
  width: 750px;
  border-style: solid;
  border-color: #d1d1d1;
  border-radius: 5px;
}
#buttons {
  margin-top: 20px;
  padding-left: 25%;
  padding-right: 25%;
}
</style>
