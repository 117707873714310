import axios from "./axios-instance";

export const getSizes = (customerId) => {
  return axios.get(`/sizes/${customerId}`);
};

export const saveSize = (payload) => {
  return axios.post("/sizes", payload);
};

export const deleteSize = (id) => {
  return axios.delete(`/sizes/${id}`);
};
