<template>
  <Toast />
  <div v-if="customer.id">
    <div class="card">
      <Steps :model="items" :readonly="true" aria-label="Form Steps" />
    </div>
    <Origin
      v-if="currentRout === '/newshipment/1'"
      :navigate="navigate"
      :customer="customer"
      :originAddress="originAddress"
      :setOriginAddress="setOriginAddress"
    />
    <Destination
      v-if="
        currentRout === '/newshipment/2' && storedShipment.shipment && addresses
      "
      :navigate="navigate"
      :shipment="storedShipment.shipment"
      :addresses="addresses"
    />
    <Dimensions
      v-if="currentRout === '/newshipment/3'"
      :navigate="navigate"
      :customer="customer"
      :setDimensions="setDimensions"
      :shipment="storedShipment.shipment"
    />
    <Pricing
      v-if="currentRout === '/newshipment/4' && storedShipment.shipment"
      :navigate="navigate"
      :customer="customer"
      :shipment="storedShipment.shipment"
      :dimensions="dimensions"
      :originAddress="originAddress"
      :setPrice="setPrice"
      :price="price"
      :showToast="showToast"
    />
    <Summary
      v-if="currentRout === '/newshipment/5'"
      :navigate="navigate"
      :originAddress="originAddress"
      :shipment="storedShipment.shipment"
      :dimensions="dimensions"
      :price="price"
      :showToast="showToast"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { readUserData } from "@/services/user";
import Steps from "primevue/steps";
import Origin from "./Origin.vue";
import Destination from "./Destination.vue";
import Dimensions from "./Dimensions.vue";
import Pricing from "./Pricing.vue";
import Summary from "./Summary.vue";
import { findByUser } from "@/api/customer";
import { shipmentStore } from "@/stores/shipment";
import { getAddresses } from "@/api/address";
import { useToast } from "primevue/usetoast";

const router = useRouter();
const customer = ref({});
const user = readUserData();
const originAddress = ref({});
const storedShipment = shipmentStore();
const addresses = ref([]);
const dimensions = ref({});
const price = ref({});
const toast = useToast();

if (!storedShipment.shipment.id) {
  router.push("/shipments");
}

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const setPrice = (data) => {
  price.value = data;
  navigate("5");
};

const setDimensions = (data) => {
  dimensions.value = data;
};

let currentRout = router.currentRoute.value.path;

const getCustomer = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
    loadAddresses();
  } catch (err) {
    console.log(err);
  }
};

const setOriginAddress = (address) => {
  originAddress.value = address;
};

getCustomer();

const navigate = (rout) => {
  router.push(rout);
};

const loadAddresses = async () => {
  const resp = await getAddresses(customer.value.id);
  addresses.value = resp.data;
  addresses.value.unshift({ alias: "Nueva dirección", id: 0 });
};

const items = ref([
  {
    label: "Origen",
    to: "/newshipment/1",
  },
  {
    label: "Destino",
    to: "/newshipment/2",
  },
  {
    label: "Dimensiones",
    to: "/newshipment/3",
  },
  {
    label: "Precios",
    to: "/newshipment/4",
  },
  {
    label: "Resumen",
    to: "/newshipment/5",
  },
]);
watch(
  () => router.currentRoute.value.path,
  () => {
    currentRout = router.currentRoute.value.path;
  }
);
</script>
