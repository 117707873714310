<template>
  <Card>
    <template #title>Dimensiones </template>
    <template #content>
      <div id="content">
        <div id="form">
          <div class="field">
            <span class="p-float-label">
              <Dropdown
                v-model="selectedSize"
                placeholder="Escoger..."
                :options="sizes"
                optionLabel="alias"
                style="width: 95%"
              />
              <label for="company-rut">Empaque predeterminado</label>
            </span>
          </div>
          <div class="formgroup-inline field">
            <span class="p-float-label">
              <InputText class="p-inputtext" v-model="selectedSize.height" />
              <label for="company-rut">Alto</label>
            </span>
            <span class="p-float-label right">
              <InputText class="p-inputtext" v-model="selectedSize.width" />
              <label for="company-rut">Ancho</label>
            </span>
          </div>
          <div class="formgroup-inline field">
            <span class="p-float-label">
              <InputText class="p-inputtext" v-model="selectedSize.length" />
              <label for="company-rut">Largo</label>
            </span>
            <span class="p-float-label right">
              <InputText
                class="p-inputtext"
                v-model="selectedSize.volumetric_weight"
              />
              <label for="company-rut">Peso Volumétrico</label>
            </span>
          </div>
          <div class="formgroup-inline field">
            <span class="p-float-label">
              <InputText
                class="p-inputtext"
                v-model="selectedSize.physical_weight"
              />
              <label for="company-rut">Peso Físico</label>
            </span>
            <span class="p-float-label right">
              <InputText class="p-inputtext" v-model="quiantity" />
              <label for="company-rut">Cantidad</label>
            </span>
          </div>
          <div class="flex align-items-center" style="padding-bottom: 10px">
            <Checkbox inputId="example1" value="Cheese" />
            <label class="ml-2"> Guardar En mis empaques </label>
          </div>
          <Button
            icon="pi pi-arrow-circle-left"
            severity="secondary"
            rounded
            outlined
            aria-label="Filter"
            style="float: left"
            @click="props.navigate('2')"
          />
          <Button
            icon="pi pi-arrow-circle-right"
            rounded
            outlined
            aria-label="Filter"
            style="float: right"
            @click="props.navigate('4')"
            :disabled="selectedSize.id === undefined"
          />
        </div>
      </div>
    </template>
  </Card>
</template>
<script setup>
import Dropdown from "primevue/dropdown";
import { ref } from "vue";
import Checkbox from "primevue/checkbox";
import { defineProps, watch } from "vue";
import { getSizes } from "@/api/size";
const props = defineProps({
  navigate: Function,
  customer: Object,
  setDimensions: Function,
  shipment: Object,
});
const selectedSize = ref({});
const sizes = ref([]);
const quiantity = ref(props.shipment.products);

const loadSizes = async () => {
  const response = await getSizes(props.customer.id);
  sizes.value = response.data;
};

watch(
  () => selectedSize.value,
  (newValue) => {
    newValue.quantity = quiantity.value;
    props.setDimensions(newValue);
  }
);

loadSizes();
</script>

<style scoped>
#content {
  display: flex;
  justify-content: center;
}
#form {
  padding: 20px;
  width: 385px;
  border-style: solid;
  border-color: #d1d1d1;
  border-radius: 5px;
}
.radio {
  padding-bottom: 10px;
}
.field {
  margin-top: 30px;
}
.right {
  margin-left: 10px;
}
</style>
