<template>
  <Card>
    <template #title>Origen </template>
    <template #content>
      <div id="content">
        <div id="form">
          <div class="field">
            <span class="p-float-label">
              <Dropdown
                v-model="selectedAddress"
                placeholder="Seleccione una dirección"
                :options="addresses"
                optionLabel="alias"
                style="width: 95%"
              />
              <label for="company-rut">Alias</label>
            </span>
          </div>
          <div class="field">
            <span class="p-float-label">
              <InputText
                id="company-web"
                class="p-inputtext"
                style="width: 95%"
                v-model="selectedAddress.address"
                disabled
              />
              <label for="company-web">Dirección</label>
            </span>
          </div>
          <div class="formgroup-inline field">
            <span class="p-float-label">
              <InputText
                id="company-rut"
                class="p-inputtext"
                v-model="selectedAddress.contact_email"
                disabled
              />
              <label for="company-rut">Email</label>
            </span>
            <span class="p-float-label right">
              <InputText
                id="company-rut"
                class="p-inputtext"
                v-model="selectedAddress.contact_phone"
                disabled
              />
              <label for="company-rut">Teléfono</label>
            </span>
          </div>
          <div class="formgroup-inline field">
            <span class="p-float-label">
              <InputText
                id="company-rut"
                class="p-inputtext"
                v-model="selectedAddress.contact_name"
                disabled
              />
              <label for="company-rut">Contacto</label>
            </span>
            <span class="p-float-label right">
              <InputText
                id="company-web"
                class="p-inputtext"
                v-model="selectedAddress.contact_complement"
                disabled
              />
              <label for="company-web">Complemento</label>
            </span>
          </div>
          <Button
            icon="pi pi-arrow-circle-left"
            severity="secondary"
            rounded
            outlined
            aria-label="Filter"
            style="float: left"
            @click="props.navigate('/shipments')"
          />
          <Button
            icon="pi pi-arrow-circle-right"
            rounded
            outlined
            aria-label="Filter"
            style="float: right"
            @click="props.navigate('2')"
            :disabled="selectedAddress.id === undefined"
          />
        </div>
      </div>
    </template>
  </Card>
</template>
<script setup>
import { defineProps, ref, watch } from "vue";
import { getAddresses } from "@/api/address";
import Dropdown from "primevue/dropdown";

const props = defineProps({
  navigate: Function,
  customer: Object,
  originAddress: Object,
  setOriginAddress: Function,
});
const addresses = ref([]);
const selectedAddress = ref(props.originAddress);

watch(
  () => selectedAddress.value,
  (val) => {
    props.setOriginAddress(val);
  }
);

const loadAddresses = async () => {
  const resp = await getAddresses(props.customer.id);
  addresses.value = resp.data;
};

loadAddresses();
</script>
<style scoped>
#content {
  display: flex;
  justify-content: center;
}
#form {
  padding: 20px;
  width: 385px;
  border-style: solid;
  border-color: #d1d1d1;
  border-radius: 5px;
}
.field {
  margin-top: 30px;
}
.right {
  margin-left: 10px;
}
</style>
