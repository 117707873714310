import { defineStore } from "pinia";

export const shipmentStore = defineStore("shipment", {
  state: () => {
    return { shipment: {} };
  },
  actions: {
    setShipment(shipment) {
      this.shipment = shipment;
    },
  },
});
